.login-section {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.login_wrapper {
  align-items: center;
  background: #ccc;
  background: url(../../assets/Images/loginKMS.jpg);
  background-size: cover;
  background-position: center;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  margin-left: 1rem;
  min-height: calc(100vh - 100px);
  padding: 30px 0 10px;
  width: calc(60% - 1rem);
  border-radius: 0px 123px;
  margin-left: 5em !important;
}

.login_wrapper img {
  width: 100%;
}

.form-login h2 {
  font-size: 24px;
  text-align: center;
  color: var(--primary-600);
}

.login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 2em 0 0;
  max-width: 520px;
  padding: 5em;
  width: 40%;
}

.login-outer,
.login {
  min-height: 100vh;

}



.form-login {
  position: relative;
}

.login label {
  color: var(--darker-gray);
  font-size: 14px;
}

.svg-bg img {
  width: 1200px;
}

.background-fixed {
  position: fixed;
  top: -256px;
  right: 0;
  left: -378px;
}

.login input.form-control {
  border-color: var(--gray-700);
  margin-top: 4px;
  line-height: 1;
  min-height: 38px;
  border-radius: 6px;
  padding: 13px;

}


.login input.form-control:hover,
.login input.form-control:focus,
.login input.form-control:active {
  border-color: var(--primary-500);
  background-color: var(--white);
  box-shadow: 0 0 3px var(--primary-500);
}

.checkbox .cr {
  background: transparent;
  border: 1px solid var(--gray-700);
  ;
}

.showHidePass {
  position: absolute;
  top: 40px;
  right: 10px;
  opacity: 0.5;
}

.user_login {
  position: absolute;
  top: 35px;
  right: 10px;
  opacity: 0.5;
}

.showHideOuterDiv {
  position: relative
}

.loginBox .checkbox {
  display: flex;
  width: 100%;
}

.checkbox-with-label {
  width: 100%;
  margin-bottom: 10px
}

.login button.btn.btn-primary {
  background: var(--primary-600);
  font-size: 14px;
  border-color: var(--primary-600);
  border-radius: 6px !important;
  font-weight: bold;
  letter-spacing: 1px;
  padding: 10px;
}


.login .form-group {
  margin-bottom: 0.9rem;
}

.remember {
  font-weight: normal;
  font-size: 14px;
  line-height: 14px;
  color: var(--text-color);
}

label.withlabel-checkbox1 {
  width: auto;
  margin-top: 5px;
}

.forgetPas {
  cursor: pointer;
  color: black;
  margin-top: 10px;
}


.login-wave {
  width: 84%;
  height: auto;
  margin: 0 auto;
  display: block;
}

img.LightSmallLogo {
  display: none;
}


/* Create a custom checkbox */
.login .with-label-checkbox .checkmark {
  background-color: transparent;
  border: 1px solid var(--primary-600);
}

/* On mouse-over, add a grey background color */
/* .login .with-label-checkbox:hover input~.checkmark {
  background-color: var(--primary-600);
  border-color: var(--primary-600);
} */

/* When the checkbox is checked, add a blue background */
.login .with-label-checkbox input:checked~.checkmark {
  background-color: var(--primary-600);
  border-color: var(--primary-600);
}
.login-mobile-image {
  display: none;
}
@media (max-width: 1100px) {
.login {
  margin: 0;
  max-width: 520px;
  padding: 3em;
  width: 50%;
}
.login_wrapper {
  padding: 30px 0 10px;
  width: calc(50% - 1rem);
  margin-left: 0px !important;
}
}


@media (max-width:767px) {
  .login {
    margin: 0;
    max-width: 100%;
    padding: 3em;
    width: 100%;
  }

  .login-mobile-image {
    display: block;
  }
  .login_wrapper {
    display: none;
  }

  .form-login {
    display: block !important;
  }
  .login {
    padding: 0;
  }
  .loginBox.w-100 {
    padding: 2rem;
  }
  .login {
    justify-content: flex-start;
  }
}