  .logout_modal_icon svg {
    fill: var(--primary-500);
    background-color: var( --primary-200);
    border-color: var( --primary-500);
    border-radius: 50%;
    height: 40px;
    width: 40px;
    padding: 6px;
  }
  .logout_modal_icon {
    background: #2b4c21;
    width: 50px;
    height: 50px;
    border-radius: 30px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }