.kitchen-modal .modal-dialog {
    max-width: 600px;
}

.password-icon .showHidePass.modalBox {
    background-color: var(--white);
    right: 12px;
    top: 33px;
    width: 16px;
}
.relayError + .showHidePass.modalBox {
    right: 38px;
} 
.switchBox .form-switch {
	padding-left: 2.5em;
}
.switchBox .form-switch .form-check-input {
    width: 32px;
    height: 18px;
    margin-top: 1px;
}
.change-password .form-check-label {
    margin-left: 10px;
  }
  .form-group.change-password {
    display: block ruby;
    text-align: center;
  }