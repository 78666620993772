.circle_icons {
  box-shadow: 0 3px 16px rgba(142, 134, 171, .05);
  border-radius: 50%;
  background: var(--gray-200);
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  width: 35px;
  height: 35px;
  line-height: 35px;
}

.aside-footer .nav-text {
  margin: 7px 0;
  padding: 0 10px;
}

.language .dropdown-menu {
  background-color: var(--gray-100);
  color: var(--gray-800);
  border-color: var(--gray-100);
  border-radius: 12px;
  box-shadow: 0px 8px 24px var(--shadow-color);
  left: 40px !important;
  top: -43px !important;
}

.product-images {
  border: 1px solid var(--gray-100);
  width: 100%;
  display: flex;
  height: 220px;
}

.product-images img {
  margin: 0 auto;
}

.auto-refresh {
  margin-top: 10px;
}

.disable {
  background-color: var(--gray-400) !important;
  opacity: 1;
  pointer-events: none;
}

.not-allowed {
  cursor: not-allowed;
}

.not-allowed {
  cursor: not-allowed;
}
.dropdown.Filter-Kitchen .dropdown-menu.show {
  width: 400px;
}
.add-list li a.clear-filter {
  font-size: 12px;
  color: #0094d9;
  font-weight: normal;
}
.dropdown.Filter-Kitchen .dropdown-toggle {
  border: 0px !important;
  border-radius: 50% !important;
}