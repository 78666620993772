.showHidePass.modalBox {
    background-color: var(--white);
    right: 30px;
    bottom: 25px;
}

.search-result {
  padding: 5px 10px;
}

  .search-result:hover {
    background-color: #efefef;
    cursor: pointer;
  }

  .results-list.custom-select {
    min-width: 10rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background: #ededed;
    background-clip: padding-box;
    border-radius: .25rem;
    position: absolute;
    width: 95%;
    z-index: 999;
    top: 77px;
    max-height: 133px;
    overflow-y: auto;

  }
  .result-box {
    position: relative;
  }