
.close:focus,
.close:hover {
    color: #31859b;
}

.profile_modal_icon {
    text-align: center;
    margin: 0 auto;
}
.profile_modal_icon svg{
    width: 60px;
    fill: var(--primary-500);

}
.p-0.bg-white.profile-close.modal-header {
    position: absolute;
    right: 20px;
    top: 20px;
  }

.add-profile-modal label svg {
    fill: var(--gray-500);
    border: 2px solid var(--gray-300);
    border-radius: 6px;
    margin: 16px 0;
    padding: 13px 0;
    width: 100%;
}

.change-password .showHidePass.modalBox {
    right: 14px;
    top: 33px;
  }

.header-bg  {
    align-items: center;
    background: #ccc;
    background: url(../../../assets/Images/header_bg.jpg);
    background-size: cover;
    background-position: center;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    margin-left: 1rem;
    height: 82px;
    padding: 0;
    width: 100%;
    border-radius: 0px 0px 0 123px;
    margin: 0;
  }
  .header-bg.modal-header button {
    position: absolute;
    right: 10px;
    top: 10px;
  }
  .profile_modal_icon {
    margin-top: -30px;
  }