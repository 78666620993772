.pageInput {
    width: 84px;
    border: none;
    color: var(--text-color);
    background-color: var(--white);
    height: 30px;
}

.totalpages {
    margin: 0 10px 0 10px;
    font-weight: bold;
}

.currentPage {
    padding-left: 10px;
    border-right: 1px solid var(--gray-300);
}

.customPages {
    padding-top: 3px;
    border: 1px solid var(--gray-300);
}


/* --=========== New CSS ============-- */

.pagination>li:first-child>a,
.pagination>li:first-child>span {
    margin-left: 0;
    border-left: 1px solid var(--gray-300);
}

.page-item:first-child .page-link {
    margin-left: 0;
}

.page-link {
    position: relative;
    display: block;
    padding: .4rem .75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: var(--black);
    background-color: var(--white);
    border: 1px solid var(--gray-300);
}

.page-number ul li a,
.page-number ul li .page-link {
    height: 25px !important;
    margin: 0;
    width: auto;
    text-align: center;
    padding: 2px 8px 3px;
    float: left;
    line-height: 18px;
    border: 1px solid var(--gray-300);
    font-size: 15px;
}

.page-number input[type="text"] {
    padding: 0 3px;
    height: 23px;
    width: 50px;
}

.customPages {
    padding-top: 0px;
    border: 1px solid var(--gray-300);
}

.currentPage {
    padding-left: 0px;
    border-right: 1px solid var(--gray-300);
    height: 30px !important;
    display: inline-block;
    line-height: 20px;
    position: relative;
}

.customPages svg {
    fill: var(--black);
}
.currentPage span.searchIcon {
    padding: 5px 10px;
    font-size: 14px;
    cursor: pointer;
    height: 30px;
    width: 30px;
    z-index: 9;
    position: absolute;
    right: 0;
    top: 0;
  }
.page-number .totalpages {
    margin: 0 10px 0 10px;
    font-weight: normal;
    font-size: 13px;
}

.page-link:hover {
    background-color: var(--primary-500);
    border-color: var(--primary-500) !important;
    color: var(--white) !important;
}

.currentPage span.searchIcon.disabled-class {
    cursor: not-allowed;
    background: var(--gray-300);
    color: var(--gray-400);
    padding: 5px 10px;
}

.page-item.disabled .page-link {
    color: var(--gray-900);
    pointer-events: none;
    cursor: auto;
    background-color: var(--gray-200);
    border-color: var(--gray-300);
}

/* --=========== End New CSS ============-- */