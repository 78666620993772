#left-sidebarNav {
    z-index: 1000;
    position: fixed;
    width: 256px;
    height: 100%;
    left: 0px;
    top: 0px;
    background: var(--white);
    -webkit-transition: all 0.1s ease;
    -moz-transition: all 0.1s ease;
    -o-transition: all 0.1s ease;
    transition: all 0.1s ease;
    transition: .3s;
    text-align: left;
    display: flex;
    flex-direction: column;
    box-shadow: 0 3px 16px rgba(142,134,171,.05);
}

/*==== NAV TOGGLE BUTTON ==== */
button#nav-toogle {
    background-color: var(--gray-100);
    width: 43px;
    height: 41px;
    font-size: 20px;
    color: var(--gray-600);
    position: absolute;
    left: calc(100% + 21px);
    top: 11px;
    border-radius: 5px;
    padding: 9px;
    box-shadow: 0 3px 16px rgba(142,134,171,.05);
  align-items: center;
  border-radius: 50%;
  background: var(--white);
}

button#nav-toogle:hover {
    background-color: var(--gray-200);
}

button#nav-toogle svg {
    overflow: hidden;
    vertical-align: middle;
}

.closeNav button#nav-toogle svg {
    margin: 7px;
}

.menu-toggle span {
    background-color: currentColor;
    height: 2px;
    transition-duration: .15s;
    transition-duration: .25s;
    transition-property: all;
    transition-timing-function: cubic-bezier(.4, 0, .2, 1);
    width: 1.25rem;
    display: block;
    margin: 5px 0 4px 3px;
}

.menu-toggle span:nth-child(2) {
    width: .75rem;
}

/*
aside:not(.closeNav) .menu-toggle span:nth-child(2) {
    display: none;
}

aside:not(.closeNav) .menu-toggle span:first-child {
    transform: rotate(-35deg);
    width: 14px;
}

aside:not(.closeNav) .menu-toggle span:nth-child(3) {
    transform: rotate(35deg);
    width: 14px;
} */

.closeNav button#nav-toogle i {
    transform: rotate(180deg);
}

button#nav-toogle i {
    float: right;
    margin-right: 20px;
}

.closeNav button#nav-toogle i {
    float: initial;
    margin-right: 0px;
}

.closeNav .aside-footer .version {
    display: none;
}

/*== Logo ==*/
#left-sidebarNav.closeNav {
    width: 55px;
}

.breadcrumb-item.active {
    color: var(--primary-500);
}

.sidebar-logo svg {
    margin-left: 69px;
    width: 204px;
    z-index: -1;
    position: relative;
}
.closeNav .sidebar-logo svg {
    width: 128px;
    margin-left: 10px;

}



/*==== NAV TEXT ==== */
#left-sidebarNav li {
    padding: 10px 6px;
    width: 100%;
    display: inline-flex;
}

#left-sidebarNav li a {
    display: flex;
    align-items: center;
    color: var(--text-color);
    width: 100%;
    line-height: 19px;
    font-size: 14px;
    position: relative;
}
#left-sidebarNav li a::before {
    content: "";
    border: 1px solid transparent;
    position: absolute;
    left: -7px;
    top: 0;
    bottom: 0;
    border-radius: 5px;
    width: 2px;
    background-color: transparent;
}
#left-sidebarNav ul {
    padding-left: 3px;
  }
#left-sidebarNav li a.active::before, #left-sidebarNav li a:hover::before {
    border-color: var(--primary-500) !important;
    background-color: var(--primary-500) !important;
}

#left-sidebarNav li a.active {
    color: var(--text-green) !important;
}
#left-sidebarNav .nav-inner .list-unstyled>li>a {
    padding: 7px;
    border-radius: 3px;
    color: var(--gray-700);
  font-weight: 400;
}
#left-sidebarNav li .nav-text h5  {
color: var(--gray-700);
font-weight: normal;
}
#left-sidebarNav .nav-inner .list-unstyled>li>a:not(.active):hover {
    color: var(--text-green);
}
#left-sidebarNav a:hover svg {
    fill: var(--text-green);
}
#left-sidebarNav li a.btn {
    color: var(--red-200);
}

/* #left-sidebarNav .list-unstyled>li>a svg {
    width: 27px;
}
.svgWidth.kitchen-icon svg {
    width: 25px !important;
  } */
#left-sidebarNav .list-unstyled>li>a.active {
    background-color: rgba(34, 197, 94, 0.05);
    
}

#left-sidebarNav .list-unstyled>li>a.active svg {
    fill: var(--primary-500);
}

#left-sidebarNav:not(.closeNav) .nav-inner .list-unstyled>li>a {
    min-width: 210px;
}

#left-sidebarNav.closeNav span.nav-text {
    display: none;
}

/* #left-sidebarNav li a.active span.nav-text {
    color: var(--white);
} */

/* #left-sidebarNav .list-unstyled>li>a.active svg path {
    fill: var(--white);
} */

/*==== DROPDOWN MENU ==== */
#left-sidebarNav .nav-inner .list-unstyled>li .dropdown-menu {
    transform: none !important;
    display: block;
    padding: 0;
    transition: all 0.4s ease;
    height: 0;
    overflow: hidden;
    opacity: 0;
    visibility: hidden;
    z-index: 1;
}



#left-sidebarNav.closeNav .nav-inner .list-unstyled>li:hover .dropdown-menu,
#left-sidebarNav:not(.closeNav) .nav-inner .list-unstyled>li .dropdown-menu.show {
    height: 45px;
    overflow-y: scroll;
    opacity: 1;
    visibility: visible;
}

#left-sidebarNav.closeNav .nav-inner .list-unstyled>li:hover .dropdown-menu.show {
    inset: 0 56px !important;
}

#left-sidebarNav.closeNav .nav-inner .list-unstyled>li.dropdown:hover::before {
    position: absolute;
    width: 16px;
    height: 16px;
    background-color: var(--white);
    right: -8px;
    top: 27px;
    transform: rotate(45deg);
    content: '';
    display: block;
    border: 1px solid var(--gray-400);
    border-top: 0 none;
    border-right: 0 none;
    z-index: 24;
}

#left-sidebarNav .nav-inner .list-unstyled>li .dropdown-toggle::after {
    margin-left: auto;
}

#left-sidebarNav.closeNav .nav-inner .list-unstyled>li .dropdown-toggle::after {
    display: none;
}

#left-sidebarNav .nav-inner .list-unstyled>li .dropdown-item {
    color: var(--gray-600);
    border-radius: 4px;
    padding: 8px;
}

#left-sidebarNav .nav-inner .list-unstyled>li .dropdown-item:hover {
    background-color: var(--gray-200);
}

#left-sidebarNav .nav-inner .list-unstyled>li .dropdown-item.active {
    background-color: transparent;
    color: var(--secondary-600);
}

/*==== LEFT SIDEBAR FOOTER ICONS ==== */
.aside-footer {
    position: absolute;
    bottom: 0;
    left: 0px;
    width: 100%;
    border-top: 1px solid var(--gray-300);
    padding: 10px 0 0;
}

.aside-footer ul {
    margin-bottom: 1px;
    width: 100%;
    padding: 0 0 0 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.aside-footer li.dropdown {
    cursor: pointer;
}

.aside-footer li {
    color: var(--black);
    text-align: left;
    cursor: pointer;
    font-size: 14px;
}

.closeNav .aside-footer ul {
    text-align: inherit;
}
.closeNav li.nav-text {
    display: none !important;
}


.aside-footer li a {
    color: var(--black);
    font-size: 15px;
}

/* .aside-footer li a span {
    padding-left: 6px;
}
*/
.aside-footer img {
    width: 22px;
}

/* .cls-1 {
    fill: #868990;
  } */


.svgWidth {
    flex-shrink: 0;
    display: inline-flex;
    min-width: 38px;
}

.aside-footer .profile_svg {
    width: 30px;
}

.logout .profile_svg {
    margin-left: 4px;
}
@media (max-width: 767px) {
    .no_message {
        width: 191px;
        max-width: 100%;
      }
    #left-sidebarNav.closeNav .nav-inner {
        display: none;
      }
      #left-sidebarNav.closeNav .aside-footer {
        display: none;
      }
      .aside-footer {
        display: block;
      }
      .nav-inner {
        display: block;
      }
      #left-sidebarNav.closeNav {
        background: transparent;
        width: 0;
        
      }
      .page_title_wrap .breadcrumb {
        display: none;
      }
      .page_title {
        padding-left: 78px;
      }
}
@media (max-width: 500px) {
      .row-eq-height {
        margin-top: 0;
      }
      #moveContent .sliding-panel-container {
        width: 100%;
      }
      .page_title {
        display: none;
      }
}