
  /*modal css for view weighted images*/

  .view-product-modal .product-images-view {
    width: 100%;
    display: flex;
    align-content: center;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 4px;
    min-height: 400px;
    padding: 30px;
  }
  .view-product-modal .Crossicons {
    position: absolute;
    top: 10px;
    background: rgb(103, 103, 106);
    border-radius: 60%;
    height: 30px;
    width: 30px;
    right: 11px;
    padding: 0px;
  }
  .view-product-modal .Crossicons svg {
    fill: #fff;
  }
  .view-product-modal .modal-dialog {
    max-width: 800px;
  }

  /*modal css for view weighted images end*/