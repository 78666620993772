.loaderContainer {
    justify-content: center !important;
    display: flex !important;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--gray-900);
    opacity: 0.6;
    cursor: wait;
    z-index: 1000;
}

.form-loader {
    left: 50%;
    top: 40%; 
    position: absolute;
}

.modal-dialog  .form-loader-img {
    max-width: 20px;
}

 .form-loader-img {
    width: 100px;
    align-self: center !important;

}