
.Kitchen-icon svg {
    width: 21px;
}
label.Kitchen-icon.btn.btn-dark {
    background: var(--primary-200);
    padding: 9px;
    border-color:var(--primary-200);
}
.group_detailsWrapper .card {
  box-shadow: 0 0px 0px 0 var(--shadow-color);
}
img.kitchen-product {
    width: 24px;
}
.group_detailsWrapper h3 {
    font-size: 14px;
}