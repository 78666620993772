html {
    height: 100%;
    width: 100%;
    font-size: var(--font-size);
}

body {

    font-family: "Inter", sans-serif;
    /* font-family: "Manrope", sans-serif; */
    background-color: var(--body-background);
    font-size: 15px;
    color: var(--text-color);
    overflow-x: hidden;
    padding: 0;
    margin: 0;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
}

* {
    outline: 0px;
}

input[type="file"]:focus,
input[type="radio"]:focus,
input[type="checkbox"]:focus {
    outline: 0px;
    outline-offset: 0;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

input[type=number] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
}

p {
    margin: 0px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 700;
}

h1 {
    font-size: 30px;
}

h2 {
    font-size: 24px;
}

h3 {
    font-size: 16px;
}

h4 {
    font-size: 15px;
}

h5 {
    font-size: 12px;
}

h6 {
    font-size: 10px;
}

ul li {
    list-style: none;
}

a {
    color: var(--primary-500);
    text-decoration: none;
    outline: none;
    cursor: pointer;
}

a:hover,
a:focus {
    color: inherit;
    text-decoration: none;
    outline: none;
}

::selection {
    background: var(--primary-500);
    color: var(--white);
}

::-moz-selection {
    background: var(--primary-500);
    color: var(--white);
}

p {
    color: var(--text-color);
}

.text-primary {
    color: var(--primary-600) !important;
}

.text-dark {
    color: var(--black) !important;
}

a.text-dark:focus,
a.text-dark:hover {
    color: var(--black) !important;
}

.text-muted {
    color: var(--gray-500) !important;
}

.card {
    border: 0px solid var(--gray-200);
    border-radius: 8px;
    background-color: var(--white);
    overflow: hidden;
    height: auto;
    box-shadow: 0 4px 24px 0 var(--shadow-color);
}

.form-group {
    position: relative;
    margin-bottom: 10px;
}

.form-control {
    min-height: 40px;
    border-radius: 5px;
    padding: 8px;
    background: var(--white);
    border: 1px solid var(--gray-300);
    font-size: 13px;
    color: var(--gray-800);
    position: relative;
}

.form-control[data-testid="test_password"] {
    padding-right: 40px;
}

.MuiOutlinedInput-notchedOutline {
    border: 1px solid var(--gray-300) !important;
}

.MuiInputBase-input {
    height: 40px !important;
    padding-top: 1px !important;
    padding-bottom: 1px !important;
}

select.form-control:not([size]):not([multiple]) {
    padding-right: 30px;
    height: 42px;
    /* padding-right: 30px; */
    background: var(--white) url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAB2AAAAdgB+lymcgAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAHPSURBVHic7dgtSANhGMDx/4YiU5TJwGAQLAaLoBaLFoOYbDabzWjWbrTJgmsmm0EsrlgsFsOCRVAQBEV0m98abgc6N+69u/fj3D0/eMruBu//2MZ4QAghhBBCCCGESJ1MwPVBK6cw7z7Mzb1AEagCXx0y1UZTTuUBFBNwYFOz0xzb/BXIAo94n4JO9AT0/3wh23SD/6RSo9UDKDk4hy27KjflgDLuv6+6p4zijyDAAHCagEPrmjMgrxrvyzfe6PrwceccKISN9w0BlQRERJ0LYDhqvG8EuExATNi5AkbjxvvGgJsERKnOLTCuK943AdwlIC5oHoBp3fG+Gbx/U64j200NmDUV75sHnh2HtppXYNFg9y9LwJvhoDDzDiwbLW5hBfiIeXAd8wmsGm5ta03hgKZn3XhlgE3cxW9Y6FOyhf34bStlijJ42xZb8SWC95nWZYE9zMfvA12WmkLrBg4wF38E9FiriSgHHKM//gTos9gRi+6FSqSFhmsFvGVE3PgK3l7iX4q7UNGy0HAt6kLlGo0LDdfCLlSMLDRcU12oGF1ouBa0UKkBc85OZ8kCUOdvfL1xLRWm8P7VveBtlw6BSacnEkIIIYQQQgghRGp8A7sjgU5M3b7vAAAAAElFTkSuQmCC") no-repeat right .75rem center/auto 12px;
}

.form-control::placeholder {
    font-size: 1em;
    line-height: 1;
}

.table td.pre_formatted_data {
    white-space: pre;
}

.rti--input,
.form-control:focus,
#focusedInput {
    box-shadow: none;
    color: var(--gray-800);
    background-color: var(--white);
}

.form-control:disabled,
.form-control[readonly] {
    /* background-color: var(--white); */
    background-color: var(--gray-400) !important;
    opacity: 1;
    cursor: not-allowed;
}

.error.form-control {
    border-color: var(--red-200);
}

/* input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-background-clip: text;
    -webkit-text-fill-color: var(--gray-800);
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px var(--white);
} */

input:-webkit-autofill {
    caret-color: var(--gray-800);
}

.rti--container {
    --rti-bg: var(--white) !important;
    --rti-border: var(--gray-200) !important;
    --rti-main: var(--primary-500) !important;
    --rti-tag: var(--secondary-500) !important;
    --rti-tag-remove: var(--white-color) !important;
}

.rti--tag span,
.rti--tag button {
    color: var(--white-color);
}

.custom-file .custom-file-label::after {
    color: var(--gray-500);
    content: "" !important;
    background: var(--gray-0) url(../Images/category_icon_vector_data-analytics.svg) no-repeat center / 20px auto;
    border-left: 1px solid var(--gray-400);
    border-radius: 0 .25rem .25rem 0;
    min-width: 30px;
}

.custom-file .custom-file-label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.border-bottom {
    border-bottom: 1px solid var(--gray-200) !important;
}

.input-group-text {
    background-color: var(--gray-100);
    border: 1px solid var(--gray-300);
}

.input-group+.input-group:not(:last-child)>.input-group-prepend>.label-style.input-group-text,
.input-group+.input-group:not(:last-child)>.custom-select:not(:first-child),
.input-group+.input-group:not(:last-child)>.form-control:not(:first-child) {
    margin-top: calc(var(--bs-border-width) * -1);
    margin-bottom: calc(var(--bs-border-width) * -1);
}

.input-group:not(:last-child)>.input-group-prepend>.label-style.input-group-text {
    border-bottom-left-radius: 0;
}

.input-group:last-child>.input-group-prepend>.label-style.input-group-text {
    border-bottom-left-radius: 4px;
}

.input-group:first-child>.input-group-prepend>.label-style.input-group-text {
    border-top-left-radius: 4px;
}

.input-group:first-child>.input-group-prepend+.custom-select:not(:first-child),
.input-group:first-child>.input-group-prepend+.form-control:not(:first-child) {
    border-top-right-radius: 4px;
}

.input-group:last-child>.input-group-prepend+.custom-select:not(:first-child),
.input-group:last-child>.input-group-prepend+.form-control:not(:first-child) {
    border-bottom-left-radius: 4px;
}

.input-group-text {
    color: var(--gray-400);
}

label {
    font-weight: normal;
    color: var(--gray-700);
    margin-bottom: .1em;
}

.p-0 {
    padding: 0px;
}

::-ms-clear {
    display: none;
}

input[disabled] {
    cursor: default;
    background: var(--gray-300);
    color: var(--gray-700);
    border-color: var(--gray-300);
}

ul {
    margin: 0px;
    padding: 0px;
}

a[disabled] {
    pointer-events: none;
    color: #95979a;
}

/*input box*/
.form-control:focus,
#focusedInput {
    border: 1px solid var(--primary-500) !important;
    box-shadow: none;
}

.button:hover:not(.disabled):not(:disabled) {
    box-shadow: 0 8px 25px -8px var(--primary-500);
}

.button::after {
    height: 100%;
    left: 0;
    top: 0;
    width: 0;
    background-color: var(--primary-400);
    content: '';
    position: absolute;
    z-index: -1;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.button:hover::after {
    width: 100%;
}

.button {
    transform: scale(1);
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
    display: inline-block;
}

.language button {
    padding: 0px !important;
    background: transparent !important;
    border: 0px !important;
    margin: 0px;
}

.dropdown-toggle::after {
    display: none;
}

/*textarea*/
textarea {
    overflow: auto;
    width: 100%;
    border: 1px solid var(--gray-300);
    padding: 5px;
    resize: none;
}

/*select*/
select option:hover {
    box-shadow: 0 0 10px 100px var(--primary-500) inset;
    background: var(--primary-500);
    color: var(--white);
}

option[selected]:hover {
    background: var(--primary-500);
    color: var(--white);
}

select[disabled] {
    cursor: default;
    background: var(--gray-300);
    color: var(--gray-700);
    border-color: var(--gray-300);
    border: 1px solid var(--gray-300);
}

/*button*/

button {
    background: transparent;
    border: none;
    cursor: pointer;
}

/* a:not(.btn):hover,
a:not([href]):not([tabindex]):focus,
a:not([href]):not([tabindex]):hover {
    color: var(--primary-500);
} */

.btn:not(.btn-sm) {
    text-align: center;
    padding: 6px 13px;
    font-size: 15px;
    border-radius: 5px;
}

.navbar-fixed-top .menu-toggle {
    border-radius: 50%;
    background: var(--white);
    box-shadow: 0 3px 16px rgba(142, 134, 171, .05);
}

.navbar-fixed-top .btn.btn-secondary {
    padding: 6px 9px 6px 5px !important;
}

.btn-sm {
    font-size: 0.75rem;
    border-radius: 7px;
}

.btn-outline-primary:hover,
.btn-outline-primary:focus {
    background-color: var(--primary-500);
    background: linear-gradient(90deg, var(--primary-500) 0%, var(--primary-800) 100%);

}

.btn.btn-primary {
    background: var(--primary-600);
    font-size: 14px;
    border-color: var(--primary-600);
    border-radius: 0.45rem !important;
    color: var(--white);
    box-shadow: none;
    background-origin: border-box;
    border-color: var(--primary-500);
    transform: scale(1);
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
    display: inline-block;
}

.btn-primary.disabled,
.btn-primary:disabled {
    color: var(--white);
    background-color: var(--primary-300);
    border-color: var(--primary-300);
}

/* .btn-primary:focus,
.btn-primary:hover:not(.disabled):not(:disabled) {
    background: linear-gradient(90deg, var(--primary-800) 0%, var(--primary-500) 100%);
    border-color: var(--primary-600);
    background-origin: border-box;
    color: var(--white-color);
} */

.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show>.btn-outline-primary.dropdown-toggle,
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
    color: var(--white-color);
    background-color: var(--primary-600);
    border-color: var(--primary-600);
    box-shadow: none;
}

.btn-outline-primary {
    color: var(--primary-600);
    border-color: var(--primary-600);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
    color: var(--primary-300);
    background-color: transparent;
    border-color: var(--primary-300);
    cursor: not-allowed;
}

.btn-light-primary {
    color: var(--primary-500);
    background-color: transparent;
    border-color: transparent;
}

.tableDesign .btn.btn-light-danger:hover,
.tableDesign .btn.btn-light-danger:focus {
    background: transparent;
    border-color: transparent;
}

.btn-secondary {
    color: var(--white);
    background-color: var(--secondary-500);
    border-color: var(--secondary-500);
}

.btn-secondary:focus,
.btn-secondary:active,
.btn-secondary:hover {
    background-color: var(--secondary-700) !important;
    border: 1px solid var(--secondary-700) !important;
    color: var(--white) !important;
}

.btn-light {
    color: var(--gray-700) !important;
    background-color: var(--gray-0);
    border: 1px solid var(--gray-0);
}

.btn-light-secondary {
    color: var(--secondary-500);
    background-color: transparent;
    border-color: transparent;
}

.badge-light-secondary,
.btn.btn-light-secondary:hover,
.btn.btn-light-secondary:focus {
    background: var(--secondary-100);
    color: var(--secondary-500);
    border-color: var(--secondary-0);
    box-shadow: none;
}

.btn-light:focus,
.btn-light:active,
.btn-light:hover {
    background-color: var(--gray-300) !important;
    border: 1px solid var(--gray-300) !important;
}

.badge-danger,
.btn-danger {
    color: var(--white);
    background-color: var(--red-200);
    border-color: var(--red-200);
}

.btn-danger:focus,
.btn-danger:hover:not(.disabled):not(:disabled) {
    background-color: var(--red-400);
    border-color: var(--red-400);
    color: var(--white);
}


.btn-light-danger {
    color: var(--red-200);
    background-color: var(--red-0);
    border-color: transparent;
}

.badge-light-danger,
.btn.btn-light-danger:hover,
.btn.btn-light-danger:focus {
    background: var(--red-0);
    color: var(--red-200);
    border-color: var(--red-0);
    box-shadow: none;
}

.badge-success,
.btn-success {
    color: var(--white);
    background-color: var(--green-200);
    border-color: var(--green-200);
}

.btn-success:focus,
.btn-success:hover:not(.disabled):not(:disabled) {
    background-color: var(--green-400);
    border-color: var(--green-400);
    color: var(--white);
}


.btn-light-success {
    color: var(--green-200);
    background-color: transparent;
    border-color: transparent;
}

.badge-light-success,
.btn.btn-light-success:hover,
.btn.btn-light-success:focus {
    background: var(--green-0);
    color: var(--green-200);
    border-color: var(--green-0);
    box-shadow: none;
}

.badge-warning,
.btn-warning {
    color: var(--white);
    background-color: var(--yellow-200);
    border-color: var(--yellow-200);
}

.btn-warning:focus,
.btn-warning:hover:not(.disabled):not(:disabled) {
    background-color: var(--yellow-400);
    border-color: var(--yellow-400);
    color: var(--white);
}


.btn-light-warning {
    color: var(--yellow-200);
    background-color: transparent;
    border-color: transparent;
}


.badge-light-dark,
.btn.btn-light-dark:hover,
.btn.btn-light-dark:focus {
    background: var(--gray-200);
    color: var(--gray-800);
    border-color: var(--gray-0);
    box-shadow: none;
}

.badge-light-warning,
.btn.btn-light-warning:hover,
.btn.btn-light-warning:focus {
    background: var(--yellow-0);
    color: var(--yellow-200);
    border-color: var(--yellow-0);
    box-shadow: none;
}

.btn-link {
    color: var(--black);
}

.btn-link:hover,
.btn-check:checked+.btn,
.btn.active,
.btn:first-child:active,
:not(.btn-check)+.btn:active {
    color: var(--primary-500);
    border-color: transparent;
}

.btn:disabled[class*="btn-light"] {
    opacity: 0.3;
    cursor: not-allowed;
    border: 0 none;
    pointer-events: unset;
    background: #e5e6e8;
}

.btn:disabled[class*="btn-light"] svg {
    fill: var(--gray-700) !important;
}

.badge[class*="badge-light"] {
    font-weight: 400;
    font-size: 0.9rem;
    border-radius: 20px;
}

.badge.badge-parking[class*="badge-light"] {
    border-radius: 0px;
    padding: 17px 20px;
    width: 100%;
    font-weight: 500;
}

.tab-action button+button {
    margin-left: 13px !important;
    margin-top: 0px !important;
}

button.close:focus {
    color: var(--gray-800);
    outline: none;
}

button:focus {
    outline: 0px !important;
}

span.closebtn {
    float: right;
    font-size: 20px;
    cursor: pointer;
}



.pagination>li>a {
    cursor: pointer !important;
    color: var(--black);
}

.pagination>.active>a:not([href]):not([tabindex]):focus,
.pagination>.active>a:not([href]):not([tabindex]):hover {
    color: var(--white);
}

.dropdown-item {
    cursor: pointer;
}

.badge-light {
    color: var(--gray-700);
    background-color: var(--gray-400);
}

/*search*/
.page-input-search::-webkit-outer-spin-button,
.page-input-search::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.page-input-search[type="number"] {
    -moz-appearance: textfield;
    appearance: textfield;
}

.searchPlaceholder::-webkit-input-placeholder {
    font-size: 9px;
}

.navbar-fixed-top .btn:not(.btn-sm) {
    padding: 6px 8px;
}

.navbar-fixed-top .btn.btn-light {
    padding: 9px;
    box-shadow: 0 3px 16px rgba(142, 134, 171, .05);
    align-items: center;
    border-radius: 50%;
    background: var(--white);
}

/*custome scrollbar*/
::-webkit-scrollbar {
    -webkit-appearance: none;
}

::-webkit-scrollbar:vertical {
    width: 7px;
}

::-webkit-scrollbar:horizontal {
    height: 7px;
}

::-webkit-scrollbar-thumb {
    background-color: var(--gray-500);
    border-radius: 4px;
}

::-webkit-scrollbar-track {
    border-radius: 4px;
}

::-webkit-input-placeholder {
    color: var(--text-color);
}

/*placeholder*/
.form-control::-moz-placeholder {
    color: var(--text-color);
}

.form-control:-ms-input-placeholder {
    color: var(--text-color);
}

.form-control:-moz-placeholder {
    color: var(--text-color);
}

/*table*/
.table>thead>tr>td.active,
.table>tbody>tr>td.active,
.table>tfoot>tr>td.active,
.table>thead>tr>th.active,
.table>tbody>tr>th.active,
.table>tfoot>tr>th.active,
.table>thead>tr.active>td,
.table>tbody>tr.active>td,
.table>tfoot>tr.active>td,
.table>thead>tr.active>th,
.table>tbody>tr.active>th,
.table>tfoot>tr.active>th {
    background-color: #ebf2f9;
}

.table>thead>tr>th,
.table>tbody>tr>th,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>tbody>tr>td,
.table>tfoot>tr>td {
    vertical-align: middle;
}

.table td.address-Tenant {
    border: 0 none;
    text-align: left;
    padding: 7px 10px;
    white-space: nowrap;
    line-height: 1.2;
    word-wrap: break-word;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
}

.table thead th {
    border-bottom: 1px solid var(--gray-100);
}

.table,
.table-responsive {
    height: 100%;
}

.table-bordered> :not(caption)>* {
    border-color: var(--gray-200);
}

.tableDesign thead {
    position: sticky;
    top: -1px;
    z-index: 4;
}

.tableDesign tbody tr td:first-child {
    border-left: 0px;
}

.tableDesign tbody tr td:last-child {
    border-right: 0px;
}

.tableDesign tbody tr td.last-column .btn[class*="btn-light"] {
    padding: 4px;
}

.tableDesign tbody tr td.last-column .btn.btn-light-success:hover {
    background: transparent;
    color: var(--green-200);
    border-color: transparent;
    box-shadow: none;
}

.tableDesign tbody tr td.last-column .btn[class*="btn-light"] svg {
    width: 18px;
    height: 18px;
    fill: var(--primary-300);
}

.tableDesign tbody tr td.last-column .btn[class*=" btn-light-danger"] svg {
    fill: var(--red-300);
    width: 14px;
    height: 14px;
}

.tableDesign tbody tr td.last-column .btn[class*=" btn-light-success"] svg {
    fill: var(--green-300);
}

.tableDesign tbody tr td.last-column .btn[class*=" btn-light-secondary"] svg {
    fill: var(--info-400);
}

.tableDesign tbody tr td.last-column .btn[class*="btn-light"][title="Edit"] svg {
    width: 18px;
    height: 18px;
}

.tableDesign tbody tr td.last-column.open-closed-icon .btn[class*="btn-light "] svg {
    width: 18px;
    height: 18px;
    margin: 0 auto;
}

.tableDesign table.table.table-bordered {
    border-left: 0px;
    border-right: 0px;
    position: relative;
    border-top: 0px;
}

.table-bordered tbody tr {
    border-bottom: 1px dashed var(--gray-200);
    border-style: dashed;
}

.table-bordered thead tr {
    border: 0px dashed;
}

.tableDesign .table thead th {
    border: 0px;
    font-weight: 600;
    padding: 13px 10px;
    color: var(--black) !important;
    background: var(--bg-white-gray);
}

.back-icon.pe-1 {
    margin-top: -1px;
}

/* .breadcrumb-item+.breadcrumb-item::before {
    color: #fff;
} */

.SelectedRow td,
.bg-row.SelectedRow,
.changingPanel tr.bg-row.SelectedRow:hover {
    background-color: #ffe5ca !important;
    color: #262626;
    cursor: pointer;
}

.row-eq-height {
    background: var(--white);
    border: 1px solid var(--card-border);
    /* box-shadow: 0 4px 24px 0 var(--shadow-color); */
    height: calc(100vh - 62px);
    overflow: auto;
}

.row-eq-height .table {
    height: auto !important;
}

.table {
    background-color: var(--gray-300);
    height: 100%;
    margin-bottom: 0;
}

.table td,
.table th {
    border: 1px solid var(--gray-200);
    text-align: left;
    padding: 7px;
    white-space: nowrap;
    color: var(--gray-800);
    font-size: 14px;
    line-height: 11px;
}

tbody {
    display: table-row-group;
    vertical-align: middle;
}

.tableFooter {
    color: var(--gray-500);
}

.tableDevices {
    border: 0;
    height: calc(100vh - 137px);
}

.last-column {
    width: 120px;
}

/*backgrounds*/
.bg-lightgray {
    background-color: var(--gray-0) !important;
}

.bg-gray {
    background-color: var(--gray-400) !important;
}

.accordion-item {
    color: var(--gray-800) !important;
    background-color: var(--white) !important;
    border: 1px solid var(--gray-200) !important;
}

.dropdown-menu {
    background-color: var(--white);
    color: var(--gray-800);
    border-color: var(--gray-100);
    border-radius: 12px;
    box-shadow: 0px 8px 24px var(--shadow-color);
    min-width: 200px;
}

.filter-dropdown .dropdown-menu {
    min-width: 300px;
    /* right: 0 !important;
    left: inherit !important; */
}

.filter-dropdown .dropdown-item:active {
    background-color: transparent !important;
}

.filter-dropdown .btn.btn-primary {
    color: var(--gray-700) !important;
    background: var(--gray-0);
    border: 1px solid var(--gray-0);
}

.filter-dropdown .btn-primary:focus,
.filter-dropdown .btn-primary:hover:not(.disabled):not(:disabled) {
    background: var(--primary-0);
    color: var(--primary-500);
    border-color: var(--primary-0);
    box-shadow: none;
}

[data-bs-theme="dark"] .dropdown-menu {
    background-color: var(--gray-400);
}

.dropdown-item:focus,
.dropdown-item:hover {
    color: var(--gray-800);
    /* background-color: var(--gray-400); */
}

/*wrapper body content*/
.wrapper {
    display: block;
    width: 100%;
}

.wrapper #moveContent {
    min-height: 100vh;
    padding: 62px 20px 0px 75px;
}

.wrapper #moveContent.ShiftLeftSide {
    padding-left: 280px;
}

/*nav bar top of page */
nav {
    position: relative;
    width: auto;
}

.navbar-fixed-top {
    position: fixed;
    right: 0;
    left: 0;
    top: 0px;
    /* background: var(--white);
    box-shadow: 0 4px 24px 0 var(--shadow-color); */
    z-index: 10;
    padding-left: 130px;
    height: 64px;
}


.ShiftLeftSide .navbar-fixed-top {
    padding-left: 340px;
}

.navbar {
    min-height: 30px;
}

.page_title {
    font-size: 1.2rem;
    /* color: var(--white); */
    line-height: 1;
    margin: 0;
}

.page_title_wrap .breadcrumb {
    margin: 6px 0 0;
    font-size: 13px;
    /* color: var(--white); */
}

/* .page_title_wrap .breadcrumb a {
    color: var(--white) !important;
} */

.page_title_wrap .breadcrumb a:hover svg {
    fill: var(--primary-400);
}

.add-list {
    margin: 0;
    display: flex;
    flex-direction: row-reverse;
    padding: 13px 20px;
    gap: 15px;
}

.add-list li {
    cursor: pointer;
}

.add-list li.disabled {
    cursor: not-allowed;
}

.add-list li a {
    cursor: pointer;
    text-align: left;
    font-size: 15px;
}

.add-list .fa,
.add-list .fas {
    color: var(--white);
}


.icon-nav.m-b-0 {
    margin-bottom: 0;
}

.page-count-no select {
    padding: 0px 0px;
    border: 1px solid var(--gray-300);
    height: 25px;
    width: auto;
    position: relative;
    margin: 0px;
}

.page-count-no select option {
    position: absolute;
    top: -5px;
}

.page-count-no select:hover {
    background: var(--white);
}

.page-number input[type="text"] {
    width: 100px;
}

.page-count-no {
    margin: 0 3px;
    padding: 0px 0 0;
}

.page-number {
    line-height: 10px;
}

.page-number ul li a,
.page-number ul li .page-span {
    height: 25px;
    margin: 0;
    width: auto;
    border-left: 0px;
    text-align: center;
    padding: 2px 8px 3px;
    line-height: 20px;
    border: 1px solid var(--gray-300);
    border-left: 0px;
}

.page-count-no {
    padding: 0 6px;
    margin-right: 0px;
    padding-right: 0px;
}

/*custome checkbox*/

.custom-checkbox .checkmark {
    position: absolute;
    top: -5px;
    left: 0;
    height: 15px;
    width: 15px;
    border: 1px solid var(--gray-700)
}

.checkbox .cr,
.radio .cr {
    position: relative;
    display: inline-block;
    border: 0 none;
    background: var(--gray-400);
    border-radius: 3px;
    width: 16px;
    height: 16px;
    margin-top: 5px;
    margin-right: 5px
}

.checkbox .checkbox-area .cr {
    margin-right: 0
}

.custom-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0
}

.checkbox label:after,
.radio label:after {
    content: '';
    display: table;
    clear: both
}

.checkbox .cr .cr-icon {
    position: absolute;
    font-size: 10px;
    line-height: 1;
    top: 2px;
    left: 2px;
    color: var(--gray-800);
}

.checkbox .cr .cr-icon .fa-check::before {
    color: var(--gray-800);
}

span.cr:focus {
    border: 1px solid var(--primary-500) !important;
}

.checkbox .cr .cr-icon,
.radio .cr .cr-icon {
    position: absolute;
    font-size: 9px;
    line-height: 0;
    top: 52%;
    left: 13%;
}

.checkbox label input[type="checkbox"],
.radio label input[type="radio"] {
    display: none;
}

.checkbox label input[type="checkbox"]+.cr>.cr-icon,
.radio label input[type="radio"]+.cr>.cr-icon {
    opacity: 0;
}

.checkbox label input[type="checkbox"]:checked+.cr>.cr-icon,
.radio label input[type="radio"]:checked+.cr>.cr-icon {
    opacity: 1;
}

.checkbox label input[type="checkbox"]:disabled+.cr,
.radio label input[type="radio"]:disabled+.cr {
    opacity: 0.5;
}

/*modal pop up width correction*/
.range-modal .modal-dialog.modal-dialog-centered {
    width: 635px;
}

/*modal pop up width csv*/
.csv-modal .modal-dialog {
    width: 500px !important;
}

.radio label,
.checkbox label {
    min-height: auto;
    padding-left: 0px;
    width: auto;
    margin: 0px;
    vertical-align: middle;
}

.checkbox {
    margin: 0px;
}

.form-check.form-switch.ml-4 {
    margin-top: -3px;
}

/*custome radio button*/
input[type="radio"]+label::before {
    content: "";
    display: block;
    position: absolute;
    top: 5px;
    height: 15px;
    width: 15px;
    background: var(--white);
    border: 1px solid var(--gray-500);
    border-radius: 8px;
    left: 0px;
}

input[type="radio"]+label::after {
    content: "";
    display: block;
    position: absolute;
    top: 8px;
    height: 8px;
    width: 8px;
    background: var(--white);
    border-radius: 8px;
    left: 3px;
}

input[type="radio"]+label {
    position: relative;
    padding: 0 0 0 18px !important;
    line-height: 25px;
    margin: 0 6px 0 0;
}

input[type="radio"] {
    position: absolute;
    opacity: 0;
    -moz-opacity: 0;
    -webkit-opacity: 0;
    -o-opacity: 0;
    width: auto;
}

input[type="radio"]:checked+label::before {
    background: var(--primary-500);
    border-color: var(--primary-500);
}

input[type="radio"]:disabled+label {
    opacity: 0.5;
}

.radio {
    margin-bottom: 0px;
}

/*modal pop up width Time Correction*/
.modal-dialog {
    max-width: 770px;
}

.modal-dialog.modal-sm {
    max-width: 400px;
}

.modal-dialog.modal-xl {
    max-width: 900px;
}

.modal {
    height: 100%;
}

.modal-body {
    padding: 40px 30px;
}

.delete_popup .modal-body {
    padding: 20px 0 40px 30px;
}

.modal-body.noBackground {
    background: transparent;
}

.modal-body .tab-action {
    margin-top: 20px;
}

.modal-header.modal-title {
    width: 100%;
}

.modal-title {
    font-size: 16px;
    text-align: center;
    margin: 0;
    line-height: 1.2;
}

.modal-content {
    border: var(--secondary-300);
    background-color: var(--white);
}

.modal-header {
    padding: 20px 30px;
    border: 0 none;
    color: var(--black) !important;
    background: var(--bg-white-gray);
    position: relative;
}

.modal-header button.close {
    opacity: 1;
    box-shadow: none;
    font-weight: normal;
    margin: 0;
    color: var(--gray-200);
    font-size: 40px;
    position: absolute;
    right: 0;
    top: 0;
    padding: 0 10px;
    line-height: 1;
    height: auto;
    text-shadow: var(--shadow-color);
}

.user-form .modal-body {
    overflow: hidden;
}

.modal-wave svg {
    position: absolute;
    top: 0;
    right: -145px;
    z-index: 1;
    width: 500px;
    height: 53px;
}

.footer-wave svg {
    position: absolute;
    bottom: 0;
    left: -111px;
    z-index: 1;
    width: 511px;
    height: 53px;
}

/* .modal-header .btn-close {
    filter: brightness(0) invert(1);
} */

.modal-header .Crossicons {
    position: absolute;
    right: 0px;
    top: 16px;
}

.modal .btn-light-danger {
    color: var(--gray-600);
    background-color: transparent;
    border-color: var(--gray-200);
}

.modal-header svg {
    fill: var(--black);
    width: 30px;
    height: 30px;
}

.modal-footer {
    text-align: right;
    background: var(--bg-white-gray);
    padding: 7px 10px 10px;
    border-top: 0 none;
    position: relative
}

.modal-footer .form-group {
    margin-bottom: 0rem;
}

.delete-popup.modal-dialog {
    width: 400px;
}

.delete_popup .modal-header {
    text-align: center;
    justify-content: center;
    background: var(--white);
}

.modal-dialog .loaderContainer {
    width: 100%;
    transform: scale(4.5);
    transform-origin: center;
}

/*modal pop up width correction*/
.correction-modal .modal-dialog {
    width: 500px;
}

/*modal pop up width user*/
.add-user-modal .modal-dialog {
    width: 460px !important;
}

/*modal pop up width imei lock*/
.imei-lock-modal .modal-content {
    max-width: 400px !important;
}

/*modal pop up width device*/
.add-device-modal .modal-dialog {
    width: 750px !important;
}

/*modal pop up width change password*/
.changePasswordModal .modal-dialog {
    width: 400px !important;
}

/*modal pop up width change logout*/
.modal-dialog.no-data-found-popup,
.modal-dialog.stop-popup,
.modal-dialog.alert_popup,
.modal-dialog.delete_popup {
    max-width: 420px;
    text-align: center;
}

.modal-dialog.delete_popup .modal-title {
    color: var(--red-200);
}

.modal-dialog.delete_popup .modal-footer {
    justify-content: center;
}

/* .modal-dialog.delete_popup .modal-title::after {
    content: '!';
    display: block;
  width: 70px;
  height: 70px;
  background: transparent;
  border-radius: 50%;
  margin: 30px auto 0;
  line-height: 70px;
  color: var(--red-200);
  font-size: 35px;
  border: 2px solid #f43d3d;
  transform: scale(0);
  transition: all 0.3s ease 0.2s;
} */

/* .show .modal-dialog.delete_popup .modal-title::after {
    transform: scale(1);
} */
/* .modal-dialog.delete_popup {
    background: url(../../assets/Images/body-bg.jpg);
} */
.alert_popup_img {
    width: 90px;
    margin: 0 auto;
    display: block;
    transform: scale(0);
    transition: all 0.3s ease 0.2s;
}

.show .modal-dialog .alert_popup_img {
    transform: scale(1);
}

.add-profile-modal .modal-dialog {
    width: 430px;
}

.errorMessage {
    font-size: 12px;
    width: 100%;
    text-align: left;
}

.modal-dialog .form-group {
    margin-bottom: 10spx;
}

.mb-1 {
    margin-bottom: 1rem !important;
}


.bootbox-body {
    line-height: 23px;
    word-break: break-word;
}

.add-deviceData-modal .modal-footer {
    border-top: 0px solid var(--gray-300);
}

/*loader css*/
@keyframes spinner-border {
    to {
        transform: rotate(360deg);
    }
}

.spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    /* vertical-align: text-bottom;
    */
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    animation: spinner-border .75s linear infinite;
    margin-left: 5px;
}

.spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: 0.2em;
}

@keyframes spinner-grow {
    0% {
        transform: scale(0);
    }

    50% {
        opacity: 1;
    }
}

.spinner-grow {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    background-color: currentColor;
    border-radius: 50%;
    opacity: 0;
    animation: spinner-grow .75s linear infinite;
}

.spinner-grow-sm {
    width: 1rem;
    height: 1rem;
}

.getFlex {
    display: flex;
}

.relayError.text-danger {
    position: absolute;
    right: 1px;
    width: 40px;
    height: calc(100% - 2px);
    top: 1px;
    text-align: center;
    background-color: var(--white);
}

.relayError.text-danger i {
    font-size: 20px;
    line-height: 1.7;
}

.relayError .tooltiptext {
    visibility: hidden;
    width: auto;
    min-width: 150px;
    background-color: var(--white);
    color: var(--red-200);
    border: 1px solid var(--red-200);
    text-align: center;
    border-radius: 6px;
    padding: 4px 0px;
    position: absolute;
    z-index: 1;
    font-size: 12px;
    right: 100%;
    top: 4px;
    z-index: 4;
    margin: 0px;
}

.relayError.text-danger:hover .tooltiptext {
    visibility: visible;
}

.no_data {
    width: 60px;
    height: 55px;
}

.message {
    height: calc(100vh - 78px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.messageLine {
    padding-top: 5px;
}

/* responsive view start*/
@media screen and (-webkit-min-device-pixel-ratio: 0) {
    .device-detail .table {
        overflow-x: auto;
    }
}

.groupSelect {
    padding: 2px;
    border: 1px solid var(--gray-300);
    border-radius: 5px;
    /* max-width: 80px;
    */
    text-overflow: ellipsis;
}

.timeCorrection {
    padding-left: 8px;
    padding-top: 1px;
}

.errMessageRange {
    position: absolute;
    top: 100%;
    left: 0;
    width: 50%;
    line-height: 1;
}

.errMessageRange:last-child {
    left: calc(50% + 10px);
}

img.gridChartIcons {
    height: 16px;
    width: 16px;
}

.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
    -webkit-transform: translate(15px, 4px) scale(1);
    -moz-transform: translate(15px, 4px) scale(1);
    -ms-transform: translate(15px, 4px) scale(1);
    transform: translate(15px, 4px) scale(1);
}

.react-bootstrap-table table {
    table-layout: inherit !important;
}

.tableHeaderFixed thead {
    background: var(--secondary-500);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}

.tableHeaderFixed thead tr {
    width: 100%;
    display: table;
}

.tableHeaderFixed {
    padding-top: 25px;
    position: relative;
}

.tableDesign.row-eq-height.tableHistory {
    height: auto;
}

.required {
    color: red;
    margin-left: 4px;
}

/* #moveContent .sliding-panel-container {
    width: 100vw;
    right: 0;
    left: auto;
    top: 0;
} */

#moveContent .sliding-panel-container.active {
    z-index: 9;
}

#moveContent .sliding-panel-container {
    background-color: transparent;
    left: auto;
    right: 0;
    top: 64px;
    width: 350px;
}

.bg_slide.panel {
    background-color: var(--white);
    height: 100vh;
}

.bg_slide.panel .bb {
    border-bottom: 1px solid var(--gray-300);
}

.group_detailsWrapper {
    height: calc(100vh - 106px);
    overflow: scroll;
}

.group_detailsWrapper .table-responsive {
    height: auto;
}

.group_detailsWrapper h3 {
    font-size: 14px;
    color: var(--gray-900);
    font-weight: 500;
}

.bg_slide .row.bb h2 {
    line-height: 1;
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
}

.bg_slide .row.bb h2.smallFont {
    font-size: 11px;
}

.accordion-body p,
.bg_slide .row p {
    word-wrap: break-word;
    word-break: break-all;
    max-width: 100%;
    font-size: 14px;
}

.bg_slide .is_blocked p {
    max-width: 100%;
}

.bg_slide hr {
    border-color: var(--gray-300);
}

.panel {
    box-shadow: 0 4px 24px 0 var(--shadow-color);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    overflow: hidden;
    /* width: 30vw; */
    margin-left: auto;
}

/* #moveContent .card.changingPanel {
    transition: all 0.4s ease;
} */
.changingPanel tr {
    cursor: pointer;
}

.changingPanel tr:hover {
    background-color: rgba(0, 0, 0, 0.1) !important;
}

.changingPanel tr .btn:disabled {
    cursor: not-allowed;
}

#moveContent .panelOpen {
    width: 77vw;
}

#moveContent.ShiftLeftSide .panelOpen {
    width: 66vw;
}

.card.mt-3.panelOpen td,
.card.mt-3.panelOpen td p {
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.card.mt-3.panelOpen .hidePanel {
    display: none;
}

.no_message {
    width: 300px;
    max-width: 100%;
}

div[data-bs-theme="dark"] .no_message {
    filter: invert(0.8);
}

.page-link {
    cursor: pointer;
}

.search-group {
    position: relative;
    padding: 0 20px;
    max-width: 330px;
}

.search-group .form-control.search-box {
    padding-left: 40px;
}

.search-group .search-icon {
    position: absolute;
    left: 29px;
    top: 7px;
    color: var(--gray-400);
}

/* custom checkbox css start */
.with-label-checkbox {
    display: block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.with-label-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.with-label-checkbox .form-control {
    min-height: auto;
    padding: 0;
}

/* Create a custom checkbox */
.checkbox-label {
    margin-left: 23px;
    width: auto;
    color: var(--gray-700);
    display: block;
}

.with-label-checkbox .checkmark {
    background-color: transparent;
    border: 1px solid var(--gray-700);
}

.with-label-checkbox .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    /* background-color: var(--primary-600); */
    border-radius: 4px;
    border: 1px solid var(--gray-600);
    ;
}

/* On mouse-over, add a grey background color */
/* .with-label-checkbox:hover input~.checkmark {
    background-color: var(--primary-600);
    border-color: var(--primary-600);
} */

/* When the checkbox is checked, add a blue background */
.with-label-checkbox input:checked~.checkmark {
    background-color: var(--primary-600);
    border-color: var(--primary-600);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.with-label-checkbox input:checked~.checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.with-label-checkbox .checkmark:after {
    left: 6px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

/* custom checkbox css end */

.react-tooltip {
    z-index: 9999;
}

.row-eq-height2 {
    height: calc(100vh - 125px);
    overflow: auto;
}

/* microsoft edge password icon */
input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
    display: none;
}

/* .panel {
    box-shadow: 20px 20px 50px var(--gray-500);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    overflow: hidden;
    width: 40vw;
    margin-left: auto;
} */
/* @media (min-width: 1400px) and (max-width:1980px) {

    #moveContent .sliding-panel-container {
        width: 600px;
    }

    .panel {
        width: 600px;
    }

    #moveContent .panelOpen {
        width: calc(100% - 600px);
    }

} */


@media (max-width:1400px) {
    #moveContent .panelOpen {
        width: 67vw;
    }

    #moveContent.ShiftLeftSide .panelOpen {
        width: 52vw;
    }

    .page_title_wrap .breadcrumb {
        font-size: 12px;
    }

    /* .navbar-fixed-top .btn:not(.btn-sm) {
    padding: 6px 4px;
} */
    .ShiftLeftSide .btn:not(.btn-sm) {
        font-size: 14px;

    }

    .ShiftLeftSide .navbar-fixed-top .btn.btn-secondary {
        padding: 6px 4px !important;
    }
}

@media (max-width:1250px) {
    .icon-nav.userTopNav .btn:not(.btn-sm) {
        padding: 6px 9px;
        font-size: 14px;
    }
}

@media (max-width:1200px) {
    .btn:not(.btn-sm) {
        padding: 6px 4px;
        font-size: 13px;
    }

    .wrapper .ShiftLeftSide .icon-nav {
        padding-left: 0;
    }

    .wrapper .ShiftLeftSide {
        padding: 0;
    }

    .wrapper .ShiftLeftSide .chartFilter {
        padding-left: 0;
    }
}

@media (max-width: 930px) {
    .icon-nav {
        height: auto;
    }


    .row-eq-height {
        height: calc(100vh - 102px);
    }
}

@media (max-width:768px) {
    .wrapper .container-fluid {
        padding: 0 10px !important;
    }

    .modal-dialog {
        width: 98%;
    }

    .icon-nav {
        height: auto;
    }

    .row-eq-height {
        height: 100%;
    }

    .icon-nav {
        padding-left: 0;
    }

    .alertVTop .groupSelect {
        width: auto;
    }

    .add-list {
        padding-left: 57px;
    }
}

@media (max-width:767px) {
    .wrapper {
        padding-left: 0;
    }

    .row-eq-height {
        height: calc(100vh - 84px);
    }
 .close-icon-detail {
        position: absolute;
        right: 0;
      }
      .card {
        margin-top: 70px;
      }
}

@media (max-width:700px) {
    .add-profile-modal .modal-dialog {
        width: 93%;
    }
    .message {
        height: auto;
        padding: 2rem;
      }
      .row-eq-height {
        height: auto;
      }
      .panelTablePagination {
        position: absolute;
        top: 13px;
        right: 19px;
      }
      .tableFooter {
        position: relative;
      }
}

@media (max-width:560px) {
    .add-list {
        padding-left: 57px;
        position: absolute;
        right: 0;
        top: 0;
      }
    .page_title {
        margin-top: 22px;
      }
    .icon-nav {
        padding: 5px 0px 2px;
    }

    .add-user-modal .modal-dialog {
        width: 97% !important;
        margin: 0 auto;
    }

    .changePasswordModal .modal-dialog {
        width: 97% !important;
        margin: 0 auto;
    }

    .range-modal .modal-dialog.modal-dialog-centered {
        width: 97% !important;
        margin: 0 auto;
    }

    .add-deviceData-modal .nav-link {
        padding: 0.5rem;
    }
}
@media (max-width:380px) {
.page_title {
    display: none;
  }
}
@media (max-width:380px) {
    .tableDesign.row-eq-height.tableHistory {
        margin-top: 71px;
    }

    .relayError .tooltiptext {
        width: 100%;
    }
}

@media (max-width:300px) {
    .currentPage {
        padding-left: 0px !important;
    }
}