.last-column .btn-light-primary {
    color: var(--primary-500);
    background-color: var(--primary-200);
    border-color: var(--primary-200);
}
.last-column .btn-light-danger {
    background-color: var( --red-0);
    border-color: var( --red-0);
}
.last-column .btn-light-success {
    background-color: var( --green-0);
    border-color: var( --green-0);
}
.last-column .btn-light-secondary {
    background-color: var( --info-100);
    border-color: var( --info-100);
}


.last-column  .btn-sm {
    border-radius: 4px;
    width: 26px;
    height: 26px;
    margin: 0 5px;
}