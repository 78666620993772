:root {
  --body-background:rgb(247, 249, 251);
  --text-green: #2b4c21;
  --primary-0: #f0f8ff;
  --primary-100: #d6ecff;
  --primary-200: rgba(23, 83, 252, 0.1);
  --primary-300: rgb(23, 83, 252);
  --primary-400: rgb(77, 113, 66);
  --primary-500: #2b4c21;
  --primary-600: #2b4c21;
  --primary-700: #051c9e;
  --primary-800: #242961;
  --primary-900: #00144d;
  --secondary-0: #fff4e6;
  --secondary-100: #fcf2c7;
  --secondary-200: #ffc98f;
  --secondary-300: #ffb066;
  --secondary-400: #ff943d;
  --secondary-500: #b9794b;
  --secondary-600: #ec6516;
  --secondary-700: #bb4607;
  --secondary-800: #993507;
  --secondary-900: #6d2001;
  --gray-0: #F8F9FA;
  --gray-100: #f3f4f6;
  --gray-200: rgb(229, 230, 232);
  --gray-300: #DBE0E5;
  --gray-400: #BEC8D0;
  --gray-500: #868990;
  --gray-600: #5B6B79;
  --gray-700: rgb(91, 107, 121);
  --gray-800: #666464;
  --gray-900: #241f26;
  --gray: rgb(134, 137, 144);
  --red-0: #fbe0e6;
  --red-100: rgb(251, 242, 239);
  --red-200: rgb(239, 40, 65);
  --red-300: #EE3B2F;
  --red-400: rgb(238, 51, 94);
  --green-0: rgba(34, 192, 60, 0.1);
  --green-100: #26B56E;
  --green-200: #0a9b41;
  --green-300: rgb(34, 192, 60);
  --green-400: #008D3A;
  --info-0: #FFF7E0;
  --info-100: rgba(0, 185, 255, 0.1);
  --info-200: #FFBF00;
  --info-300: #FFB900;
  --info-400: rgb(0, 185, 255); 
  --white: #fff;
  --white-color: #fff;
  --bg-white-gray:rgb(244, 246, 249);
  --black: #000;
  --card-border: #333333;
  --modal-header:#2b2d3b;
  --text-color: #090909;
  --shadow-color: rgba(19, 25, 32, 0.08);
  --accordian-bg: var(--white);
  --accordian-border: var(--gray-300);
  --transparent-black: rgba(191, 207, 255, 0.1);
  --transparent-white: hsla(0, 0%, 100%, 0.1);
  --font-size: 15px;
  --bs-border-color: #e9e9e9;
  --bs-border-radius: 0.3rem;
  --bs-accordion-inner-border-radius: 7px;

}


#nprogress .bar {
  height: 3px !important;
}

#nprogress .bar {
  background: #06FFF0 !important;
}

#nprogress .peg {
  box-shadow: 0 0 10px #06FFF0, 0 0 5px #06FFF0 !important;
}

#nprogress .spinner-icon {
  border-top-color: #06FFF0 !important;
  border-left-color: #06FFF0 !important;
}

.progress,
.progress-stacked {
  --bs-progress-bar-bg: var(--secondary-600) !important;
}

.disabled-li {
  pointer-events: none;
  color: #999;
  opacity: 0.5;
  /* Optional: You can style the disabled item differently */
}